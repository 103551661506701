import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';

import { formatCurrency, formatDate } from '../../../utils/common.util';

import './trial-balance.styles.scss';

const TrialBalancePrintModal = ({
  open,
  data,
  school: { name, location, logoUrl },
  cancelAction,
}) => {
  return (
    <Modal
      id="trial-balance-modal"
      className="trial-balance-modal"
      hasScrollingContent
      passiveModal={true}
      open={open}
      primaryButtonDisabled={false}>
      {data && (
        <div style={{ pageBreakAfter: 'always' }}>
          <div className="mt-15 bx--col--auto mb-10">
            <div
              className="school-logo center"
              style={{ backgroundImage: `url(${logoUrl})` }}></div>
            <h3 className="h-24 text-primary text-center">{name}</h3>
            <p className="text-14 text-grey-dark text-center">{location}</p>

            <p className="text-center text-16 text-primary font-bold mt-20 text-uppercase">
              TRIAL BALANCE : {formatDate(new Date(data.dateFrom))}
              &nbsp;-&nbsp;
              {formatDate(new Date(data.dateTo))}
            </p>
          </div>

          <div className="header-bar">
            <table className="bx--data-table table-transparent mt-10">
              <tbody>
                <tr>
                  <td colSpan="5">ROW LABEL</td>
                  <td></td>
                  <td>
                    <span className="right">OPENING</span>
                  </td>
                  <td>
                    <span className="right">DEBIT</span>
                  </td>
                  <td>
                    <span className="right">CREDIT</span>
                  </td>
                  <td>
                    <span className="right">CLOSING</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className="bx--data-table table-transparent mt-20">
              <thead>
                <tr>
                  <th colSpan="8">
                    <span className="text-primary text-12 font-bold">
                      FIXED ASSETS
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.assets.fixedAssets.ledgerCategories.map((lc) => (
                  <tr>
                    <td>{lc.uniqueId}</td>
                    <td colSpan="3">{lc.description}</td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.opening)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.debit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.credit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.closing)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="bx--data-table table-transparent mt-20">
              <thead>
                <tr>
                  <th colSpan="8">
                    <span className="text-primary text-12 font-bold">
                      CURRENT ASSETS
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.assets.currentAssets.ledgerCategories.map((lc) => (
                  <tr>
                    <td>{lc.uniqueId}</td>
                    <td colSpan="3">{lc.description}</td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.opening)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.debit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.credit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.closing)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="bx--data-table table-transparent mt-20">
              <thead>
                <tr>
                  <th colSpan="8">
                    <span className="text-primary text-12 font-bold">
                      SHORT-TERM LIABILITIES
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.liabilities.shortTermLiabilities.ledgerCategories.map(
                  (lc) => (
                    <tr>
                      <td>{lc.uniqueId}</td>
                      <td colSpan="3">{lc.description}</td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(lc.opening)}
                        </span>
                      </td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(lc.debit)}
                        </span>
                      </td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(lc.credit)}
                        </span>
                      </td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(lc.closing)}
                        </span>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>

            <table className="bx--data-table table-transparent mt-20">
              <thead>
                <tr>
                  <th colSpan="8">
                    <span className="text-primary text-12 font-bold">
                      LONG-TERM LIABILITIES
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.liabilities.longTermLiabilities.ledgerCategories.map(
                  (lc) => (
                    <tr>
                      <td>{lc.uniqueId}</td>
                      <td colSpan="3">{lc.description}</td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(lc.opening)}
                        </span>
                      </td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(lc.debit)}
                        </span>
                      </td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(lc.credit)}
                        </span>
                      </td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(lc.closing)}
                        </span>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>

            <table className="bx--data-table table-transparent mt-20">
              <thead>
                <tr>
                  <th colSpan="8">
                    <span className="text-primary text-12 font-bold">
                      CAPITAL
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.equity.capital.ledgerCategories.map((lc) => (
                  <tr>
                    <td>{lc.uniqueId}</td>
                    <td colSpan="3">{lc.description}</td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.opening)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.debit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.credit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.closing)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="bx--data-table table-transparent mt-20">
              <thead>
                <tr>
                  <th colSpan="8">
                    <span className="text-primary text-12 font-bold">
                      REVENUE
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.revenue.ledgers.map((lc) => (
                  <tr>
                    <td>{lc.uniqueId}</td>
                    <td colSpan="3">{lc.description}</td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.opening)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.debit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.credit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.closing)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <table className="bx--data-table table-transparent mt-20">
              <thead>
                <tr>
                  <th colSpan="8">
                    <span className="text-primary text-12 font-bold">
                      EXPENSES
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.expenses.ledgers.map((lc) => (
                  <tr>
                    <td>{lc.uniqueId}</td>
                    <td colSpan="3">{lc.description}</td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.opening)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.debit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.credit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(lc.closing)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="grand-total-bar">
              <table className="bx--data-table table-transparent mt-10">
                <tbody>
                  <tr>
                    <td colSpan="3">GRAND TOTAL</td>
                    <td></td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(data.opening)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(data.debit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(data.credit)}
                      </span>
                    </td>
                    <td>
                      <span className="right font-600">
                        {formatCurrency(data.closing)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-25">
            <div className="mt-20">
              <SubFooter />
            </div>

            <div className="bx--row mt-35">
              <div className="bx--col-lg-3">
                <CustomButton
                  classes="outline secondary f-width font-bold"
                  onClick={cancelAction}>
                  CLOSE
                </CustomButton>
              </div>
              <div className="bx--col-lg-3">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => window.print()}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TrialBalancePrintModal;
