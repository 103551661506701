import React from 'reactn';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';

import {
  Header,
  HeaderName,
  HeaderMenu,
  HeaderMenuItem,
  HeaderGlobalBar,
  SkipToContent,
} from 'carbon-components-react/lib/components/UIShell';

// import components
import BorderedLogo from '../bordered-logo/bordered-logo.component';
import Notifications from '../notifications/notifications.component';

// import svgs
import { ReactComponent as NotificationIcon } from '../../assets/images/notifications_new.svg';
import { ReactComponent as EmptyNotificationIcon } from '../../assets/images/notifications_empty.svg';

// import our redux stuffs
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { logoutUser } from '../../redux/user/user.actions';

import { selectNotifications } from '../../redux/selectors/notification.selectors';
import BirthdayCelebrants from './birthday-notification/birthday';

import './main-header.styles.scss';

class MainHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotifications: false,
      newNotifications: false,
      notificationsCount: this.props.notifications.filter(
        (item) => item.generatedBy !== this.props.currentUser.userId,
      ).length,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.notifications.length !== prevProps.notifications.length) {
      this.setState({
        notificationsCount: this.props.notifications.filter(
          (item) => item.generatedBy !== this.props.currentUser.userId,
        ).length,
        newNotifications: true,
      });
    }
  }

  render() {
    const { currentUser, logoutUser } = this.props;

    if (!currentUser) return null;

    const { firstName, lastName, title, school } = currentUser;

    return (
      <div className="main-header-container">
        <Header aria-label="EPOBS FMS v2">
          <SkipToContent />

          <Link to="/" className="no-decoration">
            <HeaderName prefix="" href="#" aria-label="EPOBS FMS v2">
              <BorderedLogo />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="school-name">
                {currentUser.school ? school.name : 'Parent Portal'}
              </span>
            </HeaderName>
          </Link>
          <HeaderGlobalBar>
            <BirthdayCelebrants currentUser={currentUser} {...this.props} />

            {this.state.notificationsCount > 0 ? (
              <NotificationIcon
                className={`notification-icon ${
                  this.state.newNotifications ? 'new-notifications' : ''
                }`}
                onClick={() =>
                  this.setState({
                    newNotifications: false,
                    showNotifications: !this.state.showNotifications,
                  })
                }
              />
            ) : (
              <EmptyNotificationIcon
                className="notification-icon"
                onClick={() =>
                  this.setState({
                    showNotifications: !this.state.showNotifications,
                  })
                }
              />
            )}

            <span hidden={!this.state.showNotifications}>
              <Notifications />
            </span>
            <HeaderMenu
              menuLinkName={`${firstName} ${
                lastName ? lastName : ''
              } [${title}]`}
              aria-label="EPOBS FMS v2">
              {/*<HeaderMenuItem href="#">Change Password</HeaderMenuItem>*/}
              <HeaderMenuItem href="#" onClick={() => logoutUser()}>
                Log out
              </HeaderMenuItem>
            </HeaderMenu>
          </HeaderGlobalBar>
        </Header>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  notifications: selectNotifications,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
