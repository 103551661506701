import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';

import { formatCurrency, formatDate } from '../../../utils/common.util';

import './income-statement.styles.scss';

const IncomeStatementPrintModal = ({
  open,
  data,
  school: { name, location, logoUrl },
  cancelAction,
}) => {
  return (
    <Modal
      id="income-statement-modal"
      className="income-statement-modal"
      hasScrollingContent
      passiveModal={true}
      open={open}
      primaryButtonDisabled={false}>
      {data && (
        <div style={{ pageBreakAfter: 'always' }}>
          <div className="mt-15 bx--col--auto mb-10">
            <div
              className="school-logo center"
              style={{ backgroundImage: `url(${logoUrl})` }}></div>
            <h3 className="h-24 text-primary text-center">{name}</h3>
            <p className="text-14 text-grey-dark text-center">{location}</p>

            <p className="text-center text-16 text-primary font-bold mt-20 text-uppercase">
              INCOME STATEMENT : {formatDate(new Date(data.dateFrom))}
              &nbsp;-&nbsp;
              {formatDate(new Date(data.dateTo))}
            </p>
          </div>

          <hr className="hr-grey" />

          <div>
            <h3 className="h-20 text-primary mt-40">REVENUE</h3>
            {data.revenue.ledgers.map((ledger) => (
              <table className="bx--data-table table-transparent mt-20">
                <thead>
                  <tr>
                    <th colSpan="2">
                      <span className="text-primary text-12 font-bold">
                        {ledger.description}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ledger.accounts.map((account) => (
                    <tr>
                      <td>{account.description}</td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(account.total)}
                        </span>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td>
                      <span className="text-primary font-bold">Total</span>
                    </td>
                    <td>
                      <span className="text-primary font-bold right">
                        {formatCurrency(ledger.total)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
            <div className="summary-bar">
              <span>TOTAL REVENUE</span>
              <span>{formatCurrency(data.revenue.total)}</span>
            </div>

            <h3 className="h-20 text-primary mt-20">EXPENSES</h3>
            {data.expenses.ledgers.map((ledger) => (
              <table className="bx--data-table table-transparent mt-20">
                <thead>
                  <tr>
                    <th colSpan="2">
                      <span className="text-primary text-12 font-bold">
                        {ledger.description}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ledger.accounts.map((account) => (
                    <tr>
                      <td>{account.description}</td>
                      <td>
                        <span className="right font-600">
                          {formatCurrency(account.total)}
                        </span>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td>
                      <span className="text-primary font-bold">Total</span>
                    </td>
                    <td>
                      <span className="text-primary font-bold right">
                        {formatCurrency(ledger.total)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}

            <div className="summary-bar">
              <span>TOTAL EXPENSES</span>
              <span>{formatCurrency(data.expenses.total)}</span>
            </div>

            <div
              className={`summary-bar mt-15 ${
                data
                  ? data.variance > 0
                    ? 'profit'
                    : data.variance < 0
                      ? 'loss'
                      : ''
                  : ''
              }`}>
              <span>
                {data
                  ? data.variance > 0
                    ? 'PROFIT'
                    : data.variance < 0
                      ? 'LOSS'
                      : 'VARIANCE'
                  : ''}
              </span>
              <span>{formatCurrency(data.variance)}</span>
            </div>
          </div>

          <div className="mt-25">
            <div className="mt-20">
              <SubFooter />
            </div>

            <div className="bx--row mt-35">
              <div className="bx--col-lg-3">
                <CustomButton
                  classes="outline secondary f-width font-bold"
                  onClick={cancelAction}>
                  CLOSE
                </CustomButton>
              </div>
              <div className="bx--col-lg-3">
                <CustomButton
                  classes="primary f-width font-bold"
                  onClick={() => window.print()}>
                  <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default IncomeStatementPrintModal;
