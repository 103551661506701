import React, { useState } from 'react';
import { Modal, Checkbox } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';
import SubFooter from '../../footer/sub-footer/sub-footer.component';
import {
  formatCurrency,
  formatDate,
  number2words,
} from '../../../utils/common.util';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';

import './receipt.styles.scss';

const ReceiptModal = ({
  openModal,
  selectedPayment = null,
  cancelAction,
  school,
  currentUser,
}) => {
  if (!school) {
    school = currentUser.school;
  }
  const [state, setState] = useState({
    hideBillItems: true,
  });

  const sumBillItems = () => {
    let ao = 0,
      ap = 0,
      bd = 0;

    selectedPayment?.revenues?.map((item) => {
      ao += parseFloat(item?.amountOwed);
      ap += parseFloat(item?.amountPaid);
      bd += parseFloat(item.balanceDue);
    });
    return { amountOwed: ao, amountPaid: ap, balanceDue: bd };
  };

  //console.log(selectedPayment)

  return (
    <Modal
      id="receipt-modal"
      className="student-receipt-modal-2 print-modal"
      //hasScrollingContent
      passiveModal={true}
      open={openModal}
      primaryButtonDisabled={false}>
      <div className="modal-inner">
        <div className="inner-content">
          {selectedPayment && (
            <div style={{ breakAfter: 'avoid' }}>
              <div className="mt-15  mb-10">
                <div
                  className="school-logo center"
                  style={{
                    backgroundImage: `url(${school.logoUrl})`,
                  }}></div>
                <h3 className="h-20 text-primary text-center">{school.name}</h3>
                <p className="text-14 text-grey-dark text-center">
                  {school.location}
                </p>

                <p className="text-center text-15 text-primary font-bold mt-10 text-uppercase">
                  OFFICIAL RECEIPT ({selectedPayment.uniqueId}){' '}
                  {selectedPayment.budget?.academicYearDescription} Academic
                  Year - {selectedPayment.term?.description}
                </p>

                <p className="text-center text-14 text-primary font-bold text-uppercase"></p>
              </div>

              <div className="student-info d-flex-space-between mt-10 p-l-r-24-31">
                <div
                  className="pic"
                  style={{
                    backgroundImage: `url(${selectedPayment.student?.photoUrl})`,
                  }}></div>

                <div>
                  <p className="text-14 text-grey-light">Student ID</p>
                  <h3 className="h-14">
                    {selectedPayment.student?.schoolStudentId
                      ? selectedPayment.student?.schoolStudentId
                      : selectedPayment.student?.studentId}
                  </h3>
                </div>
                <div>
                  <p className="text-14 text-grey-light">Student Name</p>
                  <h3 className="h-14">{selectedPayment.student?.fullName}</h3>
                </div>

                <div>
                  <p className="text-14 text-grey-light">Class/Grade</p>
                  <h3 className="h-14">
                    {selectedPayment.classLabelDescription}
                  </h3>
                </div>
              </div>

              <div>
                <table
                  className="bx--data-table table-transparent"
                  hidden={state.hideBillItems}>
                  <thead>
                    <tr>
                      <th>Bill Item</th>
                      <th>Amount Owed</th>
                      <th>Amount Paid</th>
                      <th className="text-right">Balance Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedPayment.revenues.map((revenue) => (
                      <tr key={revenue.id}>
                        <td>{revenue.studentBillItem?.feeDescription}</td>
                        <td>{formatCurrency(revenue.amountOwed)}</td>
                        <td className="font-bold">
                          {formatCurrency(revenue.amountPaid)}
                        </td>
                        <td className="text-right">
                          {formatCurrency(revenue.balanceDue)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="">
                <hr className="grey-hr mt-10" hidden={state.hideBillItems} />
                <div className="ml-16">
                  <div className="mt-10">
                    <span className="h-14 text-primary">Total Amount Owed</span>
                    <span>
                      {' '}
                      &nbsp;&nbsp;&nbsp;{' '}
                      {number2words(selectedPayment?.amountOwed)}
                    </span>
                    <span className="right h-14 text-primary text-right">
                      {formatCurrency(selectedPayment?.amountOwed)}
                    </span>
                  </div>
                  <div className="mt-10">
                    <span className="h-14 text-primary">Total Amount Paid</span>
                    <span>
                      {' '}
                      &nbsp;&nbsp;&nbsp;{' '}
                      {number2words(selectedPayment?.totalAmount)}
                    </span>
                    <span className="right h-14 text-primary text-right">
                      {formatCurrency(selectedPayment.totalAmount)}
                    </span>
                  </div>
                  <div className="mt-10">
                    <span className="h-14 text-primary">Total Balance Due</span>
                    <span>
                      {' '}
                      &nbsp;&nbsp;&nbsp;{' '}
                      {number2words(selectedPayment?.balanceDue)}
                    </span>
                    <span className="right h-14 text-primary">
                      {formatCurrency(selectedPayment.balanceDue)}
                    </span>
                  </div>
                </div>

                <hr className="grey-hr mt-10" />

                <div className="d-flex-space-between">
                  <div className="">
                    <div className="mt-5 ml-16">
                      <p className="text-14 mt-10">Description</p>
                      <p className="text-14 text-grey">
                        {selectedPayment.description
                          ? selectedPayment.description
                          : 'N/A'}
                      </p>
                    </div>
                    <div className="mt-5 ml-16">
                      <p className="text-14 mt-10">Payment Mode</p>
                      <p className="text-14 text-grey">
                        {selectedPayment?.paymentMode}
                      </p>
                    </div>
                    {(selectedPayment.dateReversed ||
                      selectedPayment.revisedPayment) && (
                      <h1 className="text-danger mt-10 not-authentic">
                        THIS RECEIPT IS NOT AUTHENTIC
                      </h1>
                    )}
                  </div>
                  <div className="">
                    <div className="mt-5 ml-16">
                      <p className="text-14 mt-10">Date</p>
                      <p className="text-14 text-grey">
                        {formatDate(selectedPayment.paymentDate)}
                      </p>
                    </div>

                    <div className="mt-5 ml-16">
                      <p className="text-14 mt-10">Recorded By</p>
                      <p className="text-14 text-grey">
                        {`${selectedPayment.recordedBy.firstName} ${
                          selectedPayment.recordedBy.lastName
                            ? selectedPayment.recordedBy.lastName
                            : ''
                        } ${
                          selectedPayment.recordedBy.parent ? ', Parent' : ''
                        }`}
                      </p>
                    </div>
                  </div>
                </div>

                <SubFooter />
              </div>
            </div>
          )}
        </div>
        <div className="inner-footer">
          <div className="bx--row align-center">
            <div className="bx--col-lg-3">
              <CustomButton
                classes="outline secondary f-width font-bold mt-10"
                onClick={cancelAction}>
                CLOSE
              </CustomButton>
            </div>
            <div className="bx--col-lg-6">
              <Checkbox
                id="allItem"
                labelText="Hide/Show Bill items"
                className="mt-40 no-print"
                checked={state.hideBillItems}
                onChange={(value) => {
                  setState({
                    ...state,
                    hideBillItems: value,
                  });
                }}
              />
            </div>
            <div className="bx--col-lg-3">
              <CustomButton
                classes="primary f-width font-bold mt-10"
                onClick={() => window.print()}>
                <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(ReceiptModal);
