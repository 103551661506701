import axios from 'axios';
import ERRORS from './errors';

const devBaseUrl = 'http://localhost:5000/api/';
const prodBaseUrl = 'https://epobsschoolsuiteapi.azurewebsites.net/api/';

const apiBaseUrl = prodBaseUrl;

export const http = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// create axios config object
const createConfig = (authToken) => {
  if (!authToken) return null;

  return {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
};

// create api response
const createResponse = (success, data, message) => ({
  success,
  message,
  data,
});

// fetch data
export const fetchData = (url, authToken = null) =>
  axios
    .get(`${apiBaseUrl}${url}`, createConfig(authToken))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));

// post data
export const postData = (url, data, authToken = null) =>
  axios
    .post(`${apiBaseUrl}${url}`, data, createConfig(authToken))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error, authToken ? false : true));

// update data
export const putData = (url, data, authToken = null) =>
  axios
    .put(`${apiBaseUrl}${url}`, data, createConfig(authToken))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));

// patch data
export const patchData = (url, data, authToken = null) =>
  axios
    .patch(`${apiBaseUrl}${url}`, data, createConfig(authToken))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));

// delete data
export const deleteData = (url, authToken = null) =>
  axios
    .delete(`${apiBaseUrl}${url}`, createConfig(authToken))
    .then((response) => createResponse(true, response.data, null))
    .catch((error) => handleError(error));

// handler error
export const handleError = (error, isLogin = false) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        const message = isLogin
          ? error.response.data.message
          : ERRORS.LOGIN_EXPIRED;
        return createResponse(false, null, message);
      case 404:
        return createResponse(false, null, ERRORS.NOT_FOUND);
      case 403:
        return {
          success: false,
          message:
            'You do not have access to this resource. Contact your system administrator to grant you access.',
        };
      case 400:
      case 409:
        if (error.response.data.error) {
          return createResponse(false, null, error.response.data.error);
        }

        return createResponse(false, null, error.response.data.message);
      default:
        return createResponse(false, null, ERRORS.UNKNOWN_SERVER_ERROR);
    }
  }

  return createResponse(false, null, ERRORS.UNKNOWN_ERROR);
};

export const SWRFetcher =
  (token) =>
  (path, config = { headers: { Authorization: `Bearer  ${token}` } }) =>
    http
      .get(path, config)
      .then((resp) => resp.data)
      .catch((err) => {
        //console.log(err.response);
        const error = new Error(
          err.response?.data?.message ||
            'An error occurred while fetching the data.',
        );
        error.status = err.response?.status;
        throw error;
      });
