import React from 'react';
import { withRouter } from 'react-router-dom';
import { Plus, Printer, RefreshCw } from 'react-feather';
import {
  Search,
  Select,
  SelectItem,
  Loading,
  Pagination,
} from 'carbon-components-react';

import MainHeader from '../../components/main-header/main-header.component';
import Mainmenu from '../../components/menu/main-menu/main-menu.component';
import AddFeesModal from '../../components/modal/add-fees/add-fees.component';
import BillStudentsModal from '../../components/modal/bill-students/bill-students.component';
import QuickActionCard from '../../components/card/quick-action-card/quick-action-card.component';

import Footer from '../../components/footer/footer.component';
import ConfirmDeleteModal from '../../components/modal/confirm-delete/confirm-delete.component';
import BillingFeesModal from '../../components/modal/billing-fees-modal/billing-fees.component';

//import svg icon
import { ReactComponent as ContactGreen } from '../../assets/images/card-icons/contact-green.svg';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/selectors/user.selectors';

import { connect } from 'react-redux';

import './billing.styles.scss';
import { selectAcademicYears } from '../../redux/selectors/academic-year.selectors';
import {
  setAcademicYears,
  setTerms,
} from '../../redux/actions/academic-year.actions';
import { selectBillings } from '../../redux/selectors/billing.selectors';
import {
  setBillings,
  setBillingFees,
  removeBillingFee,
} from '../../redux/actions/billing.actions';
import {
  getBillings,
  getBillingFees,
  deleteBillingFee,
} from '../../api/billing.service';
import { getAcademicYears, getTerms } from '../../api/academic-year.service';
import { formatDate } from '../../utils/common.util';
import { getClasses } from '../../api/class.service';
import { selectClasses } from '../../redux/selectors/class.selectors';
import { setClasses } from '../../redux/actions/class.actions';

import FetchBillings from './fetch-billings/fetch-billings.components';

class BillingPage extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      query: '',
      academicYear: '',
      status: '',
      loading: false,
      errorMessage: null,
      openBillStudentsModal: false,
      openAddFeesModal: false,
      openImportModal: false,
      openConfirmDeleteModal: false,
      billings: props.billings,
      academicYears: [],
      feeId: 0,
      feeLedgerAccountId: undefined,
      feeClassId: -1,
      feeAmount: '',
      openBillingFeesModal: false,
      paginate: {
        page: 1,
        pageSize: 50,
      },
    };
  }

  componentDidMount() {
    if (this.props.billings.length === 0) {
      //this.fetchBillings();
    }

    if (this.props.classes.length === 0) {
      getClasses(this.props.currentUser.authToken).then((response) => {
        if (response.success) {
          this.props.setClasses(response.classes);
        }
      });
    }

    getAcademicYears(this.props.currentUser.authToken, 0, 5000).then(
      (response) => {
        if (response.success) {
          this.props.setAcademicYears(response.academicYears);
        } else {
          this.setState({ academicYears: this.props.academicYears });
        }
      },
    );

    getTerms(this.props.currentUser.authToken, -1).then((response) => {
      if (response.success) {
        this.props.setTerms(response.terms);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.billings.length !== prevProps.billings.length) {
      this.setState({ billings: this.props.billings });
    }
  }

  fetchBillings = async () => {
    this.setState({ loading: true, errorMessage: null });

    let response = await getBillings(this.props.currentUser.authToken);

    if (!response.success) {
      // else show error message
      this.setState({ loading: false, errorMessage: response.message });

      return;
    }

    this.setState({ loading: false });

    this.props.setBillings(response.billings);

    // get fees
    response = await getBillingFees(this.props.currentUser.authToken, -1);

    if (!response.success) {
      // show error message
      this.setState({
        feesErrorMessage: response.message,
      });

      return;
    }

    this.props.setBillingFees(response.billingFees);
  };

  filter = () => {
    let billings = this.state.billings;

    if (this.state.academicYear.length > 0) {
      billings = billings.filter(
        (billing) =>
          billing.academicYear.id === parseInt(this.state.academicYear),
      );
    }

    if (this.state.status.length > 0) {
      billings = billings.filter(
        (billing) => billing.status === this.state.status,
      );
    }

    if (this.state.query.length > 0) {
      billings = billings.filter(
        (billing) =>
          billing.academicYear.description
            .toLowerCase()
            .includes(this.state.query.toLowerCase()) ||
          billing.term.description
            .toLowerCase()
            .includes(this.state.query.toLowerCase()) ||
          billing.class.description
            .toLowerCase()
            .includes(this.state.query.toLowerCase()),
      );
    }

    // paginate
    const { page, pageSize } = this.state.paginate;
    const start = (page - 1) * pageSize;
    const end = start + pageSize;

    return billings.slice(start, end);
  };

  render() {
    const billings = this.filter();

    return (
      <div>
        <MainHeader />

        <BillStudentsModal
          openModal={this.state.openBillStudentsModal}
          cancelAction={() => this.setState({ openBillStudentsModal: false })}
        />

        <ConfirmDeleteModal
          openModal={this.state.openConfirmDeleteModal}
          description={this.state.deleteDescription}
          deleteAction={this.state.deleteAction}
          deleteSuccessAction={this.state.deleteSucessAction}
          cancelAction={() => this.setState({ openConfirmDeleteModal: false })}
        />

        <AddFeesModal
          openModal={this.state.openAddFeesModal}
          id={this.state.feeId}
          classId={this.state.feeClassId}
          general={true}
          feeAmount={this.state.feeAmount}
          feeLedgerAccountId={this.state.feeLedgerAccountId}
          cancelAction={() =>
            this.setState({
              openAddFeesModal: false,
            })
          }
        />

        <BillingFeesModal
          openModal={this.state.openBillingFeesModal}
          editAction={(fee) => {
            this.setState({
              openAddFeesModal: true,
              feeId: fee.id,
              feeAmount: fee.amount,
              feeClassId: fee.classId ? fee.classId : -1,
              feeLedgerAccountId: fee.ledgerAccountId,
            });
          }}
          deleteAction={(fee) => {
            this.setState({
              openConfirmDeleteModal: true,
              deleteAction: async () => {
                return await deleteBillingFee(
                  this.props.currentUser.authToken,
                  {
                    id: fee.id,
                  },
                );
              },
              deleteSucessAction: () => {
                this.props.removeBillingFee(fee.id);
                this.setState({ openBillingFeesModal: true });
              },
              deleteDescription: `Confirm deletion of ${fee.ledgerAccountDescription}. This action cannot be reversed`,
            });
          }}
          cancelAction={() =>
            this.setState({
              openBillingFeesModal: false,
            })
          }
        />

        <div className="billing page-container">
          <div>
            <span className="h-18">Quick Actions</span>
            <span className="right">
              <Mainmenu />
            </span>
          </div>

          <div className="mt-20">
            <div className="bx--row">
              <div className="bx--col-md-2">
                <div
                  className="card-add-billing pointer"
                  onClick={() => {
                    this.setState({
                      openBillStudentsModal: true,
                    });
                  }}>
                  <div className="plus-icon">
                    <Plus size="18" strokeWidth="3" />
                  </div>
                  <div className="text-label">NEW BILLING</div>
                </div>
              </div>

              <div className="bx--col-md-2">
                <QuickActionCard
                  description="Billing Fees"
                  leftActionText="ADD"
                  rightActionText="VIEW"
                  actionEvent1={() =>
                    this.setState({
                      openAddFeesModal: true,
                      feeId: 0,
                      feeAmount: '',
                      feeClassId: -1,
                      feeLedgerAccountId: -1,
                    })
                  }
                  actionEvent2={() => {
                    this.setState({
                      openBillingFeesModal: true,
                    });
                  }}>
                  <ContactGreen color="#fff" />
                </QuickActionCard>
              </div>

              {/*<div className="bx--col-md-2">
                <QuickActionCard
                  description="Voided Billings"
                  hideAction2={true}
                  leftActionText="VIEW"
                  actionEvent1={ev => {
                    this.setState({ openEmployeesListModal: true });
                  }}
                >
                  <ContactRed />
                </QuickActionCard>
                </div>*/}
            </div>
          </div>

          <hr className="grey-hr mt-20" />

          <FetchBillings
            onSetBillings={(_billings) => {
              this.props.setBillings(_billings);
            }}
            onSetBillingFees={(bf) => {
              this.props.setBillingFees(bf);
            }}
            onViewBilling={(b) => {
              this.props.history.push(`/billing/${b.id}`);
            }}
          />

          <Footer fixed={false} showLinks={false} />
        </div>
      </div>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  billings: selectBillings,
  academicYears: selectAcademicYears,
  classes: selectClasses,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setBillings: (billings) => dispatch(setBillings(billings)),
  setBillingFees: (billingFees) => dispatch(setBillingFees(billingFees)),
  setTerms: (terms) => dispatch(setTerms(terms)),
  setAcademicYears: (academicYears) =>
    dispatch(setAcademicYears(academicYears)),
  setClasses: (classes) => dispatch(setClasses(classes)),
  removeBillingFee: (billingFeeId) => dispatch(removeBillingFee(billingFeeId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BillingPage),
);
