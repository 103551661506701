import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import Slider from '../../../components/slider/slider.component';

import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  Tag,
  Loading,
} from 'carbon-components-react';

import CustomButton from '../../../components/custom-button/custom-btn.component';
import BorderedLogo from '../../../components/bordered-logo/bordered-logo.component';
import Footer from '../../../components/footer/footer.component';

import forgotPasswordBg from '../../../assets/images/forgot_password_bg.png';

import { confirmActivation } from '../../../api/school.service';

import './activate-school.styles.scss';

class ActivateSchoolPage extends React.Component {
  constructor(props) {
    super(props);

    this.pathname = props.history.location.pathname;
    if (this.pathname === '/account/reset-password/') {
      this.btnText = 'RESET PASSWORD';
    } else {
      this.btnText = 'ACTIVATE SCHOOL';
    }

    let params = queryString.parse(props.history.location.search);

    this.school = params.school;
    this.name = params.name;
    this.code = params.code;
    this.email = params.email;

    if (
      this.school === undefined ||
      this.name === undefined ||
      this.email === undefined ||
      this.code === undefined
    ) {
      props.history.push('/account/login');
    }

    // set state
    this.state = {
      password: '',
      confirmPassword: '',
      showSuccess: false,
      requestInProgress: false,
      errorMessage: null,
      btnText: this.btnText,
    };
  }

  // handle onchange event handler for input fields
  handleChange = (event) => {
    // destructure value and name of event.target
    const { value, name } = event.target;

    // update state
    this.setState({ [name]: value });
  };

  // validate data entered
  validateData = () =>
    this.state.password.length >= 6 &&
    this.state.confirmPassword.length >= 6 &&
    this.state.password === this.state.confirmPassword;

  // reset event handler
  confirmActivation = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      requestInProgress: true,
      errorMessage: null,
      btnText: null,
    });

    // initiate request
    const response = await confirmActivation(
      this.email,
      this.code,
      this.state.password,
      this.pathname,
    );

    // check response
    if (!response.success) {
      // registration failed, change state to show error message
      this.setState({
        requestInProgress: false,
        btnText: this.btnText,
        errorMessage: response.message,
      });

      return;
    }

    // succeeded, set state to show success
    this.setState({ showSuccess: true });
  };

  render() {
    return (
      <div className="activate-school">
        <div className="bx--row">
          <div className="bx--col-lg-5">
            <div className="ml-85 mr-85">
              <div className="mt-60 mb-50">
                <BorderedLogo />
              </div>

              <div hidden={this.state.showSuccess}>
                {this.pathname !== '/account/reset-password/' ? (
                  <div>
                    <h3 className="text-primary font-bold text-24 mt-30">
                      Hello! {this.name},
                    </h3>
                    <h4 className="text-primary-dark mt-10">
                      Activate&nbsp;
                      <span className="font-bold">
                        {this.school.toUpperCase()}
                      </span>
                    </h4>
                    <p className="mb-30 mt-10 text-14">
                      Create a password to activate your school's account
                    </p>
                  </div>
                ) : (
                  <div>
                    <h3 className="text-primary font-bold text-24 mt-30">
                      Hello! {this.name},
                    </h3>
                    <h4 className="text-primary-dark mt-10">
                      Reset Password for&nbsp;
                      <span className="font-bold">
                        {this.school.toUpperCase()}
                      </span>
                    </h4>
                    <p className="mb-30 mt-10 text-14">
                      Create a new password to regain access to your school's
                      account
                    </p>
                  </div>
                )}
              </div>

              <div hidden={this.state.showSuccess}>
                <Form action="post">
                  <FormGroup legendText="">
                    <FormItem>
                      <TextInput.PasswordInput
                        id="password"
                        name="password"
                        labelText="Password"
                        value={this.state.password}
                        placeholder="Enter new password"
                        onChange={this.handleChange}
                      />
                    </FormItem>
                  </FormGroup>

                  <FormGroup legendText="">
                    <FormItem>
                      <TextInput.PasswordInput
                        id="confirmPassword"
                        name="confirmPassword"
                        labelText="Confirm password"
                        value={this.state.confirmPassword}
                        placeholder="Re-enter password"
                        onChange={this.handleChange}
                      />
                    </FormItem>
                    <p className="text-12">
                      Password should be at least 6 character long
                    </p>
                  </FormGroup>

                  <CustomButton
                    classes="primary f-width mt-20 mb-40"
                    onClick={this.confirmActivation}
                    type="submit"
                    disabled={
                      !this.validateData() || this.state.requestInProgress
                    }>
                    {this.state.btnText}

                    <div hidden={this.state.btnText != null}>
                      <div className="d-flex-center">
                        <Loading withOverlay={false} small={true} />
                      </div>
                    </div>
                  </CustomButton>

                  <span>
                    Back to&nbsp;
                    <Link
                      to="/account/login"
                      className="text-primary text-14 font-bold">
                      Login
                    </Link>
                  </span>

                  <div hidden={this.state.errorMessage == null}>
                    <Tag className="tag-f-w mt-30" type="red">
                      {this.state.errorMessage}
                    </Tag>
                  </div>
                </Form>
              </div>

              <div className="success" hidden={!this.state.showSuccess}>
                {this.pathname !== '/account/reset-password/' ? (
                  <div>
                    <h3 className="text-primary font-bold text-22 mt-30">
                      {this.school.toUpperCase()}
                      <br />
                      <span className="text-primary-dark text-18">
                        Activated Successfully!
                      </span>
                    </h3>
                    <p className="text-16 text-grey-dark mt-20">
                      <span className="font-bold text-primary">
                        Hello {this.name},<br />
                      </span>
                      <span className="text-14">
                        Your School have been activated successfully. Click the
                        button below and login in with your password to start
                        using&nbsp;
                        <span className="font-bold">EPOBS FMS.</span>
                        <br />
                        <br />
                        We hope you enjoy the experience, reach out to support
                        if you face any challenge.
                      </span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <h3 className="text-primary font-bold text-22 mt-30">
                      {this.school.toUpperCase()}
                      <br />
                      <span className="text-primary-dark text-18">
                        Password Reset Successful!
                      </span>
                    </h3>
                    <p className="text-16 text-grey-dark mt-20">
                      <span className="font-bold text-primary">
                        Hello {this.name},<br />
                      </span>
                      <span className="text-14">
                        Your School's password reset was successful. Click the
                        button below and login in with your new password
                      </span>
                    </p>
                  </div>
                )}
                <CustomButton
                  classes="primary f-width mt-50 mb-40"
                  onClick={() => this.props.history.push('/account/login')}>
                  LOGIN
                </CustomButton>
              </div>

              <Footer />
            </div>
          </div>
          <div className="bx--col-lg-7 fixed-right padding-0">
            <div className="bx--row">
              <Slider />
            </div>
            <img className="img-f-w-obj-cover" src={forgotPasswordBg} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default ActivateSchoolPage;
