import React from 'react';
import { Modal } from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { Printer } from 'react-feather';

import { formatCurrency, formatDate } from '../../../utils/common.util';

import './template.styles.scss';

const TemplateModal = ({
  open,
  title,
  topContent,
  topContentData = [], //[{label:"",text:""}]
  content,
  school,
  closeAction,
}) => {
  return (
    <Modal
      id="template-modal"
      className="template-modal print-modal"
      //hasScrollingContent
      passiveModal={true}
      open={open}
      primaryButtonDisabled={false}>
      <div className="modal-inner">
        <div className="inner-content">
          <div style={{ breakAfter: 'avoid' }}>
            <div className="mt-15 bx--col--auto mb-10">
              <div
                className="school-logo center"
                style={{
                  backgroundImage: `url(${school.logoUrl})`,
                }}></div>
              <h3 className="h-20 text-primary text-center">{school.name}</h3>
              <p className="text-13 text-grey-dark text-center">
                {school.location}
              </p>

              <p className="text-center text-16 text-primary font-bold mt-10 text-uppercase">
                {title}
              </p>
            </div>

            <div className="info mt-10">
              {/* <div>
            <p className="text-14 text-grey-light">Class</p>
            <h3 className="h-14">{class_}</h3>
          </div>

          <div>
            <p className="text-14 text-grey-light">Class Label</p>
            <h3 className="h-14">{classLabel}</h3>
          </div>

          <div>
            <p className="text-14 text-grey-light">Status</p>
            <h3 className="h-14">{studentStatus}</h3>
          </div> 

          <div>
            <p className="text-14 text-grey-light">Total List</p>
            <h3 className="h-14">{data?.length}</h3>
          </div>
          */}
              {topContent}

              {topContentData?.map((el) => (
                <div>
                  <p className="text-14 text-grey-light">{el.label}</p>
                  <h3 className="h-14">{el.text}</h3>
                </div>
              ))}

              {!topContent && topContentData.length === 0 && (
                <div>
                  <p className="text-14 text-grey-light">Date Generated</p>
                  <h3 className="h-14">{formatDate(new Date())}</h3>
                </div>
              )}
            </div>

            <div>
              <div>{content}</div>
            </div>
          </div>
        </div>
        <div className="inner-footer">
          <div className="bx--row nm mt-10">
            <div className="bx--col-lg-3">
              <CustomButton
                classes="outline secondary f-width font-bold"
                onClick={closeAction}>
                CLOSE
              </CustomButton>
            </div>
            <div className="bx--col-lg-6"></div>
            {/* <div className="bx--col-lg-4">
              <CustomButton
                classes="secondary  f-width font-bold"
                onClick={downloadAction}
              >
                <Download size="11" strokeWidth="5px" />
                &nbsp; DOWNLOAD
              </CustomButton>
                      </div> */}
            <div className="bx--col-lg-3">
              <CustomButton
                classes="primary f-width font-bold"
                onClick={() => window.print()}>
                <Printer size="11" strokeWidth="5px" /> &nbsp; PRINT
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TemplateModal;
